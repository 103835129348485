@tailwind base;
@tailwind components;
@tailwind utilities;
#ttid{height:100vh}
canvas{margin:auto}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.lock{
  touch-action: none;

}
.checker{
  background-color:
    #fff; /* Comment: Sets the background color of the element to white */
  background-image: linear-gradient( /* Comment: Creates a linear gradient for the checkerboard */
    45deg, /* Comment: Specifies the angle of the gradient */

    #cccccc 25%, /* Comment: Sets the color and position of the first color stop */

    transparent 25%, /* Comment: Sets the color and position of the second color stop */

    transparent 75%, /* Comment: Sets the color and position of the third color stop */

    #cccccc 75%, /* Comment: Sets the color and position of the fourth color stop */

    #cccccc /* Comment: Sets the color of the fifth color stop */
  ),
  linear-gradient( /* Comment: Creates another linear gradient for the checkerboard */
    -45deg, /* Comment: Specifies the angle of the gradient */

    #cccccc 25%, /* Comment: Sets the color and position of the first color stop */

    transparent 25%, /* Comment: Sets the color and position of the second color stop */

    transparent 75%, /* Comment: Sets the color and position of the third color stop */

    #cccccc 75%, /* Comment: Sets the color and position of the fourth color stop */

    #cccccc /* Comment: Sets the color of the fifth color stop */
  );
  background-size: 40px 40px; /* Comment: Sets the size of the background squares */
  background-repeat: repeat;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
